import {
  AuthTypes,
  TextDirections,
  GameMenuActionTypes,
  Guidelines,
  Language,
  TimerConfig,
} from 'Shared/enums';
import type { NumberInputGamesConfig, PlayerConfig, WordGamesConfig } from 'Types/player-config';
import { KeyboardLayoutBaseType } from 'Player/input';
import type { NumberInputGamePuzzleConfig, WordGamePuzzleConfig } from 'Types/puzzle';
import { NumberInputMode, NumberInputModeConfig } from 'Types/number-games';

export const defaultConfig: PlayerConfig = {
  authType: AuthTypes.None,
  direction: TextDirections.LTR,
  disableAutoStart: false,
  disableDefaultCss: false,
  disableExternalLinks: false,
  disabledGameButtons: [],
  enableDebugMode: false,
  enableHelpTools: false,
  enableTimer: TimerConfig.Off,
  feedbackForm: {
    showStars: true,
    showComments: true,
    showFollowUp: false,
  },
  finishModal: {
    show: true,
    emoticon: true,
    timer: true,
    stats: false,
    share: false,
    hideShareUrl: false,
    showFeedbackLink: false,
  },
  guidelines: Guidelines.Off,
  keyboardLayout: KeyboardLayoutBaseType.QWERTY,
  language: Language.nl,
  menuConfig: {
    poppedOutActions: [GameMenuActionTypes.help],
    menuType: 'button-title-menu',
    hideTitle: false,
    disableMenuButton: false,
  },
  usePersistentStorage: true,
  removeOldSaveGames: true,
  showAutoOnboarding: undefined,
  showShareModal: true,
  zoomLevel: 1.5,
};

export const defaultWordGameConfig: Partial<WordGamesConfig<WordGamePuzzleConfig>> = {
  wordGoal: 10,
  disableWikiLinks: false,
  showMaximumWordCount: false,
};

export const defaultNumberInputGameConfig: Partial<
  NumberInputGamesConfig<NumberInputGamePuzzleConfig>
> = {
  inputMode: NumberInputModeConfig.Cycle,
};
